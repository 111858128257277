#navbar {
  background-color: $primary-blue;
  @include shadow;

  .brand-logo {
    background-image: url("./images/BCID_H_rgb_rev.png");
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    height: 3.5rem;
    margin-right: 0.5rem;
    vertical-align: middle;
    width: 12rem;
  }

  .header {
    border-bottom: 2px solid $primary-yellow;
    margin: 0;
    padding: 0.5rem 1rem;
  }

  .logged-in-info {
    align-items: center;
    color: $white;
    display: flex;
    float: right;
    height: 100%;
    text-align: right;

    .credit-balance {
      color: $white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .organization-name {
      font-size: 1rem;
      margin: 0;
    }
  }

  .navbar {
    align-items: center;
    background-color: $default-background-blue;
    display: flex;
    margin: 0;
    padding: 0 16rem 0 1rem;
    position: relative;
  }

  .navbar-nav,
  .user-control-panel {
    a {
      color: $white;
      font-size: 0.9rem;
      height: 100%;
      padding: 0.5rem 1rem;
      text-align: center;

      &.active {
        border-bottom: 1px solid $primary-yellow;
      }
    }
  }

  .nav-item,
  .user-control-panel li {
    position: relative;

    &:not(:first-child):before {
      border-right: 1px solid $default-background-grey;
      bottom: 25%;
      content: "";
      height: 50%;
      left: 0;
      position: absolute;
      width: 1px;
    }
    span.logout {
      color: $white;
      font-size: 1rem;
      height: 100%;
      padding: 0.5rem 1rem;
      text-align: center;
      cursor: pointer;
    }
  }

  .navbar-nav a {
    display: flex;

    * {
      align-self: center;
    }
  }

  .navbar-toggler {
    color: $white;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    text-align: center;
  }
  #navbarDropdown {
    width: 185px;
  }
  .user-control-panel {
    bottom: 0;
    color: $white;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0 1rem 0 0;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;

    li > * {
      align-items: center;
      display: flex;

      &.dropdown-menu {
        display: block;
        width: 200px;

        a, button {
          background-color: transparent;
          border: none;
          color: $default-text-black;
          font-size: inherit;
          padding: 0;
          text-align: left;
          width: 100%;

          .icon {
            display: inline-block;
            text-align: center;
            width: 3rem;
          }

          &:hover {
            text-decoration: none;
          }
        }

        .dropdown-item {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .display-name {
      background-color: transparent;
      border: none;
      color: $white;
      height: 100%;
      padding: 0.5rem 1rem;
    }

    .notifications,
    .help {
      font-size: 1.3rem;
      padding: 0.2rem 1rem;
    }
  }

  h1 {
    color: $white;
    display: inline-block;
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
    vertical-align: middle;
  }

  @media (max-width: $md) {
    .header {
      padding: 0;
    }

    .logged-in-info {
      width: 100%;

      > div {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        float: none;
        width: 100%;
      }
    }

    .navbar {
      padding: 0;
    }

    .navbar-nav {
      border-top: 1px solid $primary-yellow;
    }

    .nav-item {
      &:not(:first-child):before {
        display: none;
      }

      .active {
        background-color: $default-background-grey;
        border: none;
        color: $default-text-black;
      }
    }

    .user-control-panel {
      bottom: unset;
    }
  }

  @media (max-width: 695px) {
    .header {
      text-align: center;

      .brand-logo {
        display: none;
      }

      h1 {
        padding: 0.5rem 0;
      }
    }
  }
}
