.myr-alert{
    display: flex;
    flex-direction: row;
    padding: 1rem .5rem;
    border: 1px solid $border-grey;
    background-color: #f8f0f0;
    margin: 0.5rem 0;
    span {
      margin-top: auto;
      margin-bottom: auto;
      svg {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
    text-align: center;
    align-items: center;
}

.myr-proccessed{
    display: flex;
    flex-direction: row;
    padding: 1rem .5rem;
    border: 1px solid $border-grey;
    background-color: $background-light-blue;
    margin: 0.5rem 0;
    span {
      margin-top: auto;
      margin-bottom: auto;
      svg {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
}

.myr-alert-header{
    font-size: 1rem;
    font-weight: bold;
}

.myr-alert-wrapper{
    display: flex;
    padding-top: 0.5rem;
}

.icon-wrapper{
    display: flex;
    padding-top: 0.25rem;
    padding-right: 1rem;
    padding-left: 1rem;
}

.myr-column-wrapper{
    display: flex;
    flex-direction: column;
}

.checkmark{
    color: $primary-blue
}

#analyst-checkbox{
    margin-right: 1rem;
}