#login-page {
  background-image: url('./images/bg.jpg');
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  margin: 0;
  min-height: 100%;
  padding: 0;
  position: relative;
  width: 100%;

  #header {
    height: 15%;
    margin: 1rem 0;
    padding: 0 5rem;
    position: fixed;
    width: 100%;
  
    .text {
      color: $white;
      font-size: 2.5rem;
      line-height: normal;
      position: relative;
      top: 2rem;
      @include text-shadow;
    }
  }

  #link-bceid {
    background-color: $primary-blue;
    color: $white;

    &:hover {
      background-color: $default-background-blue;
    }

    .text {
      display: inline-block;
      margin-right: 0.25rem;
      vertical-align: middle;
    }

    .display-name {
      background-image: url('./images/bceid.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      color: transparent;
      display: inline-block;
      height: 1.5rem;
      vertical-align: middle;
      width: 6rem;
    }
  }

  #link-idir {
    background-color: $white;
    border: 1px solid $primary-blue;
    color: $primary-blue;

    &:hover {
      background-color: $default-background-blue;
      color: $white;
    }

    .display-name {
      font-weight: bold;
    }
  }

  #main-content {
    background-color: $white;
    background-color: rgba(255, 255, 255, 0.8);
    bottom: 0;
    height: 100%;
    left: 0;
    min-height: 40rem;
    position: absolute;
    top: 0;
  }

  .brand-logo {
    background-image: url('./images/BCID_H_rgb_pos.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 10rem;
    margin: 1rem auto;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .buttons-section {
    align-self: center;
    padding: 1rem 2.5rem;
    width: 100%;

    .button {
      border-radius: 0.5rem;
      display: block;
      font-size: 1.5rem;
      font-weight: normal;
      margin: 0.5rem auto 1rem;
      padding: 1rem 0.5rem;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
  
    .button:not(:first-child) {
      border-top: 1px solid $primary-yellow;
    }

    .divider {
      background-color: $yellow;
      height: 2px;
      margin: 5vh auto;
      width: 80%;
    }

    .section {
      color: $default-text-blue;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
    }
  }

  .flex-container {
    align-content: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @media (min-width: $md) {
    #header {
      padding-left: 35rem;
    }

    #main-content {
      width: 30rem;
    }  
  }
  
  @media (max-width: $md) {
    #header {
      background-color: $primary-blue;
      display: flex;
      height: auto;
      margin: 0;
      min-height: 5rem;
      padding: 1rem;
      position: relative;
      z-index: 999;
  
      .text {
        align-self: center;
        font-size: 4vw;
        margin: 0 auto;
        text-align: center;
        top: unset;
      }
    }

    #link-bceid, #link-idir {
      font-size: 1.5rem;
    }

    #link-bceid .display-name {
        height: 100%;
        min-width: 5rem;
        width: 10vw;
    }

    #main-content {
      min-width: 20rem;
      width: 100%;
    }

    .buttons-section {
      min-width: 30rem;
      padding-bottom: 0;
      padding-top: 0;
      width: 50%;
    }

    .flex-container {
      flex-direction: column;

      .brand-logo {
        align-self: center;
        background-image: url('./images/BCID_V_rgb_pos.png');
        background-position: center;
        background-size: contain;
        height: 20vh;
        margin: 0;
        min-height: 15rem;
        position: relative;
      }
    }
  }
}