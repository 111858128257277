.grey-border-area {
  border: 1px solid $border-grey;
  padding: 1rem 1rem;
}
@media (min-width: 496px) {
  .agreement-input {
    width: 300px !important;
  }
}
#credit-agreements-form {
  .credit-agreement-form-row {
    background-color: $default-background-grey;
    padding: 1rem;
    margin: 1rem 0 1rem 0;
  }
  label {
    font-weight: bold;
    color: $default-text-blue;
    margin-bottom: 0;
    margin-right: 2rem;
  }

  input[type="radio"] {
    margin-right: 0.5rem;
  }
}
#assessment-edit {
  table {
    width: 100%;
    td {
      padding: 0 1rem 0 1rem;
      line-height: 35px;

      li {
        line-height: normal;
      }
    }
    tr {
      margin-top: 1rem;
    }
    .small-column {
      color: $default-text-blue;
      width: 15%;
    }
    .large-column {
      color: $default-text-blue;
      padding-left: 1rem;
      width: 20%;
    }
  }

  .assessment-credit-adjustment {
    .form-group {
      background-color: $default-background-grey;
      padding: 1rem;

      label {
        color: $default-text-blue;
        margin-bottom: 0;
      }

      input[type="radio"] {
        margin-right: 0.5rem;
      }
    }
  }
}
.main-list-item {
  font-weight: bold;
}
.sub-list-item {
  list-style-type: circle;
  margin-left: 1rem;
}
.text-underline {
  text-decoration: underline;
}
#assessment-details {
  .not-in-compliance {
    background-color: #f2dede;
  }
  .text-grey {
    color: grey;
  }
  table {
    width: 100%;
    td {
      padding: 0 1rem 0 1rem;
      line-height: 35px;
    }
    tr {
      margin-top: 1rem;
    }
    .subclass {
      font-weight: bold;
      background-color: $default-background-grey;
    }
    .small-column {
      width: 15%;
    }
    .large-column {
      color: $default-text-blue;
      padding-left: 1rem;
      border-right: 4px solid white;
    }
  }
  .grey-border-area {
    border: 1px solid $border-grey;
    padding: 1rem 1rem;
  }
  .ql-container {
    height: auto !important;
  }

  .ql-editor {
    min-height: 150px !important;
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .comment-box {
    border: 1px solid $border-grey;
    width: 100%;
    padding: 0.5rem 0.5rem;
  }
}
#compliance-report-list {
  .btn-group a {
    color: $default-text-black;
    display: block;
    text-decoration: none;

    &:hover {
      background-color: $primary-yellow;
    }
  }

  .dropdown-menu {
    left: unset;
    right: 0;
  }
}

#compliance-supplier-information-details,
#assessment-edit {
  .supplier-information {
    border: 1px solid $border-grey;
  }

  .ldv-makes {
    background-color: $default-background-grey;
    max-width: 500px;

    .btn-primary {
      background-color: $primary-blue;
      width: 100%;
    }

    .disabled {
      background-color: $form-background-grey !important;
    }

    .list {
      background-color: $white;
      border: 1px solid $border-grey;

      .delete button {
        background-color: transparent;
        border: none;
        color: $red;
      }
    }
  }
}
#compliance-consumer-sales-details {
  .consumer-sales {
    border: 1px solid $border-grey;
  }
  .forecast-report {
    border: 1px solid $border-grey;
    border-top: none;
    .totals-table {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      table {
        border-collapse: separate;
        border-spacing: 0 0.5em;
        td {
          padding: 0 1rem 0 1rem;
          line-height: 35px;
        }
      }
    }
    .totals-header {
      background-color: $default-background-grey;
      font-weight: bold;
    }
    .report-checkmark {
      color: green;
      margin-right: 8px;
    }
  }

  .credit-request-link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }
  .previous-ldv-sales,
  .previous-ldv-avg {
    border: 1px solid $border-grey;
    background-color: $default-background-grey;
    height: 100%;
  }
  .textbox-first,
  .textbox-second,
  .textbox-third {
    width: 30%;
  }
  .ldv-sales {
    background-color: $default-background-grey;
  }
  .textbox-sales {
    width: 15%;
  }
}
#compliance-obligation-page {
  border: 1px solid $border-grey;
  padding: 1rem;

  .no-border {
    border: none;
  }

  .credit-selection {
    input[type="radio"] {
      margin-left: 3px;
    }

    td {
      background-color: $background-warning;
    }
  }

  .ldv-sales {
    height: 50px;
    line-height: 30px;
    font-weight: bold;
    background-color: $default-background-grey;
  }
  .compliance-reduction-table {
    border: 1px solid $border-grey;
    #obligation-sales-input {
      display: flex;
    }
  }
  table {
    width: 100%;
    border: 1px solid $border-grey;

    td {
      padding: 0 1rem 0 1rem;
      line-height: 35px;
    }
    tr {
      margin-top: 1rem;
    }
    .subclass {
      font-weight: bold;
      background-color: $default-background-grey;
    }
    .small-column {
      width: 15%;
    }
    .large-column {
      color: $default-text-blue;
      // width: 70%;
      padding-left: 1rem;
      border-right: 4px solid white;
    }
  }
  #offset-table {
    input {
      max-width: 125px;
      margin-left: 1rem;
    }
    td {
      line-height: 35px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

#compliance-sign-off {
  label.clickable {
    cursor: pointer;
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
