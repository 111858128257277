.compliance-report-tabs {
  .nav-item {
    align-self: end;
    padding: 1rem 1.5rem;

    &.SAVED {
      a {
        border-bottom-color: $background-warning;
      }

      &.active a {
        border-bottom-color: $primary-yellow;
      }
    }

    &.UNSAVED {
      &.active a {
        border-bottom-color: $primary-yellow;
      }
    }

    &.CONFIRMED, &.SUBMITTED, &.RECOMMENDED, &.RETURNED {
      a {
        border-bottom-color: $background-light-blue;  
      }
      
      &.active a {
        border-bottom-color: $default-text-blue;
      }
    }

    &.ASSESSED {
      a {
        border-bottom-color: $background-light-green;
      }

      &.active a {
        border-bottom-color: $alert-success;
      }
    }

    a,span {
      border-bottom: 1rem transparent solid;
      color: $default-text-black;
      display: block;
    }

    .disabled {
      opacity: .3;
    }
  }
}