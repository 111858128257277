.accordion {
  margin: 0;
  padding: 0;
  border-radius: 4px;
  border: 1px solid darkgray;
}

.accordion-panel {
  margin: 0;
  padding: 0;

  .accordion-title {
    background: $default-background-grey;
    position: relative;
    border-top: 1px solid darkgray;
    line-height: 2rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;

    .accordion-button {
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0.25rem;

      border: none;
      background: inherit;
      color: $default-link-blue;
    }
  }
}

.card {
  border-radius: 0;

  .card-header {
    border-radius: 0;

    .btn-link {
      color: $default-link-blue;
    }
  }  
}

.accordion-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: .5rem .5rem;
  border: 1px solid $default-text-black;
  color: $default-text-black;
  margin: 0.5rem 0;
  span {
    margin-top: auto;
    margin-bottom: auto;
    svg {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    .toggler {
      background-color: transparent;
      border: none;
    }
  }
}
