.compliance-report-summary-grey {
    background-color: $default-background-grey;
    padding: 1rem;
}
.summary-page {
  border: 1px solid $border-grey;
}
#compliance-summary-consumer-sales-table {

  width: 100%;
  tr {
    line-height: 30px;
  }

}
#summary-credit-activity {
  line-height: 30px;
  width: 100%;

    .a-class {
      border-right: 4px solid white;
      border-left: 4px solid white;
      padding-right: 1rem;
    
  }
  tbody {
  border-top: 15px solid transparent;
  }
  .text-credit-reduction{
    color:red;
  }
}