.bcgov-callout {
    padding: 25px;
    border-left: 10px solid #38598a;
    margin: 16px 0;
    background-color: #f2f2f2;
    }
    
    .bcgov-callout h1, h2, h3, h4 {
    margin: 0;
    }

#comment-input {
    .ql-container {
        height: auto !important;
      }
      .ql-editor {
        min-height: 150px !important;
        max-height: 300px;
        overflow: hidden;
        overflow-y: scroll;
        overflow-x: scroll;
      }
      .comment-box {
        border: 1px solid $border-grey;
        width: 100%;
        padding: 0.5rem 0.5rem;
      }
}