#sales-details {
  .actions {
    padding: 1rem;
    margin: 1rem 0 1rem 0;
    background-color: $default-background-grey;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .table {
    margin: 1rem 0 0;
  }
}

#sales-edit {
  h2 {
    clear: both;
  }

  .bordered {
    border: 1px solid $border-grey;
    padding: 0.5rem;
  }

  .content {
    background-color: $default-background-grey;
  }

  .compact {
    border-radius: 4px;
    clear: both;
    float: left;
  }

  .files {
    margin: 1rem -1px 0;

    .filename {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .row:nth-child(even) {
      background-color: $default-background-grey;
    }

    .actions {
      padding: 0;

      button {
        background-color: transparent;
        border: none;

        &.delete {
          color: $red;
          text-align: center;
        }
      }
    }
  }

  .sales-upload-grey {
    color: $default-text-black;
  }
}
