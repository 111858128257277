div.detail-field {

  line-height: 2em;

  .label {
    font-weight: bold;
  }
  .value {

  }
}
