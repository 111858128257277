.ReactTable {
  .-odd {
    background-color: $default-background-grey;
  }

  .icbc-warning {
    .warning, .validated {
      background-color: $background-warning !important;
    }

    &.warning-icbc-make .icbc-make {
      background-color: $background-warning !important;
    }

    &.warning-icbc-model-year .icbc-model-year {
      background-color: $background-warning !important;
    }

    &.warning-sales-date .sales-date {
      background-color: $background-warning !important;
    }

    &.warning-vin {
      .icbc-make, .icbc-model, .icbc-model-year, .vin {
        background-color: $background-warning !important;
      }
    }
  }

  .icbc-danger, .icbc-warning.icbc-danger {
    .icbc-model-year, .icbc-model, .icbc-make, .warning, .validated {
      background-color: $background-danger !important;
    }
  }

  .clickable {
    cursor: pointer;
  
    &:hover {
      background-color: $primary-yellow;
    }
  }
  
  .rt-noData {
    top: 70%;
  }
}