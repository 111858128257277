/* BC Sans Regular */
@font-face {
  font-family: "BCSans";
  src: url("./fonts/BCSans-Regular.woff") format("woff");
}

/* BC Sans Bold */
@font-face {
  font-family: "BCSans";
  font-weight: bold;
  src: url("./fonts/BCSans-Bold.woff") format("woff");
}

/* BC Sans Bold + Italic */
@font-face {
  font-family: "BCSans";
  font-style: italic;
  font-weight: bold;
  src: url("./fonts/BCSans-BoldItalic.woff") format("woff");
}

/* BC Sans Italic */
@font-face {
  font-family: "BCSans";
  font-style: italic;
  src: url("./fonts/BCSans-Italic.woff") format("woff");
}

@mixin shadow {
  @content;
  box-shadow: 0px 3px 3px 1px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0px 3px 3px 1px rgba(51, 51, 51, 0.5);
  -webkit-box-shadow: 0px 3px 3px 1px rgba(51, 51, 51, 0.5);
}

@mixin text-shadow {
  text-shadow: 2px 2px rgba(51, 51, 51, 0.5);
}

/* variables for colours */
$alert-danger: #721c24;
$alert-success: #155724;
$background-light-green: #d4edda;
$background-light-blue: #cce5ff;
$background-danger: #fff1f2;
$background-warning: #fffed2;
$border-grey: #e0e0e0;
$default-background-blue: #38598a;
$default-background-grey: #f2f2f2;
$default-link-blue: #568dba;
$default-text-black: #494949;
$default-text-blue: #1a5a96;
$form-background-grey: #fcfcfc;
$nav-border: #dee2e6;
$primary-blue: #003366;
$primary-yellow: #fcba19;
$react-border: rgba(0, 0, 0, 0.1);
$red: #eb0022;
$white: #ffffff;
$yellow: #e3ab2b;

/* variables for screen sizes */
$md: 991px;

body,
html,
#root {
  background-color: $default-background-grey;
  height: 100%;
}

body {
  color: $default-text-black;
  font-family: "BCSans", sans-serif;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

#main {
  min-height: 80%;
  // padding-bottom: 3rem;
}

#content {
  background-color: $white;
  border: 2px solid $border-grey;
  margin: 2rem 2rem 1rem 2rem;
  padding: 1rem;

  .alert:first-child:last-child {
    margin: -1.5rem;
  }
}

.action-bar {
  background-color: $default-background-grey;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 0;
  padding: 1rem;
  text-align: right;

  &.no-bg {
    background-color: transparent;
  }

  > * {
    align-self: center;
    display: flex;
  }

  .left-content > * {
    margin-right: 1rem;
  }

  .right-content > * {
    margin-left: 1rem;
    white-space: nowrap;
    align-self: center;
  }

  #supplier {
    width: auto;
  }

  label {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    .right-content > * {
      margin-left: 0;
    }
  }
}

.alert-danger {
  .alert-heading {
    color: $alert-danger;
  }
}

.background-danger {
  background-color: $background-danger !important;
}

.btn {
  &.btn-outline-danger {
    border-color: $red;
    color: $red;

    &:hover {
      background-color: $red;
      color: $white;
    }
  }

  &.btn-outline-primary {
    border-color: $primary-blue;
    color: $primary-blue;

    &:hover {
      background-color: $primary-blue;
      color: $white;
    }
  }
}

.button {
  background-color: $white;
  border: 1px solid $default-background-blue;
  border-radius: 0.2rem;
  color: $primary-blue;
  padding: 0.5rem 1rem;

  svg {
    margin-right: 0.25rem;
  }

  &:disabled {
    background-color: $default-background-grey;
    opacity: 0.6;
  }

  &.primary {
    background-color: $default-background-blue;
    color: $white;
  }

  &.primary:disabled {
    background-color: rgba(56, 89, 138, 0.5);
    color: $white;
    border-color: transparent;
  }

  &.text-danger {
    border-color: $red;
  }
}

.clear {
  clear: both;
}

.highlight {
  background-color: $background-warning;
}

.link {
  background-color: transparent;
  border: none;
  color: $default-link-blue;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: underline;
}

.link-disabled {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: underline;
}

.modal-backdrop {
  opacity: 0.4;
}

.nav-tabs {
  margin: 0;

  li {
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    padding: 1rem 0;

    &.active {
      background-color: $white;
      border: 1px solid $nav-border;
      border-bottom: none;

      a,
      button {
        color: $default-text-black;
      }
    }

    a {
      color: $default-link-blue;
      padding: 1rem 1.5rem;
    }

    button {
      background-color: transparent;
      border: none;
      color: $default-link-blue;
      line-height: normal;
      margin: 0;
      padding: 0 1.5rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.page {
  padding: 0;

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.75rem;
  }

  h6 {
    font-size: 0.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $default-text-blue;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }
}

.text-red {
  color: $red;
}

.text-blue {
  color: $default-text-blue;

  &.disabled,
  .disabled {
    color: rgba(26, 90, 150, 0.5);
  }
}

.text-black {
  color: $default-text-black;
}

.well {
  background-color: $default-background-grey;
  display: flex;
  margin: 1rem 0;
  padding: 1rem;
  text-align: left;

  .button svg {
    margin-right: 0.5rem;
  }

  > * {
    margin-left: 1rem;
    margin-right: 1rem;
    align-self: center;
    display: flex;
  }
}

.file-upload {
  &.disabled {
    background-color: $default-background-grey;
    color: #afafaf;
    border: 2px dashed $border-grey;
  }

  &:not(.disabled) {
    border: 2px dashed $default-link-blue;
    background: $white;
  }

  padding: 1rem;
  text-align: center;

  svg {
    font-size: 2rem;
    margin-left: auto;
    margin-right: auto;
  }
}

#danger-text {
  color: $red;
  font-weight: bold;
}

.ql-editor {
  height: auto;
}

button {
  &.inline-edit {
    color: #003366;
    border: none;
  }
}

.center-horizontal-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.info-icon {
  color: $default-link-blue;
  height: inherit;
  margin-right: 6px;
}
.tooltip {
  background-color: #2D2D2D !important;
  opacity: 100% !important;
  max-width: 500px;
}
