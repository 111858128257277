#organization-details {
  .organization-address {
    background-color: $default-background-grey;
    border: 1px solid $border-grey;
    padding: 1rem;

    a {
      color: $default-link-blue;
    }

    label {
      margin: 0;
      padding: 0;
      min-width: 10rem;
    }

    .value {
      color: $default-text-blue;
      font-weight: bold;
    }
  }
}
.records-address {
  @media (min-width: 1200px) and (max-width: 1563px) {
    height: 151px;
  }
  @media (min-width: 1564px) {
  height: 131px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 168px;
  }
  @media (min-width: 809px) and (max-width: 991px) {
    height: 193px;
  }
  @media (min-width: 768px) and (max-width: 808px) {
    height: 217px;
  }
  }

