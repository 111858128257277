#balance {
  font-weight: bold;
  margin-bottom: 1rem;
}
.transaction-table {
  width: 80vw;

  thead {
    background-color: $default-background-grey;

    th {
      text-align: center;
    }
  }

  tr:nth-child(even) {
    background-color: $default-background-grey;
  }

  .balance-a {
    border-left: 3px solid white;
    text-align: center;
  }
  .balance-b {
    padding-right: 10px;
    text-align: center;
  }
  .date-column {
    width: 10vw;
    padding-left: 10px;
  }
}

#upload-verification-data {
  label {
    font-weight: bold;
    color: $default-text-blue;
    text-align: center;
  }

  div.check {
    color: green;
    text-align: center;
  }

  input[type='date'] {
    border-radius: 5px;
    border: 2px solid #616161;
    padding: 10px;
    position: relative;
  }

  .bordered {
    border: 1px solid $border-grey;
    padding: 0.5rem;
  }

  .content {
    background-color: $default-background-grey;
  }

  .files {
    margin: 1rem -1px 0;

    .filename {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .row:nth-child(even) {
      background-color: $default-background-grey;
    }

    .actions {
      padding: 0;

      button {
        background-color: transparent;
        border: none;

        &.delete {
          color: $red;
          text-align: center;
        }
      }
    }
  }

  .progress-bar {
    background-color: $default-text-blue;
  }
}
.credit-summary-table {
  border-bottom: none;
  border-right: none;
  display: inline-table;
}

#credit-transfers-details {
  .status-alert{
    width:91.5%;
  }
  .ql-container {
    height: auto !important;
  }

  .ql-editor {
    min-height: 100px !important;
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .comment-box {
    border: 1px solid $border-grey;
    width:91.5%;
    padding: 0.5rem 0.5rem;  
  }

  #alert-warning {
    display: flex;
    flex-direction: row;
    padding: 1rem 0.5rem;
    border: 1px solid #8b1a1a;
    color: #8b1a1a;
    margin: 0.5rem 0;
    width:91.5%
  }
  .form {
    background-color: $form-background-grey;
    border: 1px solid $border-grey;
    .text-blue {
      margin: 1rem 0 1rem 0;
      font-size: 1.15rem;
    }

    textarea {
      margin: 0 1rem 0 0;
    }
  }

  .transfer-summary-table {
    padding: 5px;
    border: 1px solid $border-grey;
    display: inline-table;
    .rt-td {
      border: 1px solid $border-grey;
    }
    .rt-th {
      padding: 1px;
      font-weight: bold;
      border: 1px solid $border-grey;
    }
  }
}
#transfer-sign-off {
  color: $default-text-blue;
  .text-grey {
    color: grey;
  }
}

#credit-request-details {
  .status-alert {
    margin: 0;
  }

  .ql-container {
    height: auto !important;
  }

  .ql-editor {
    min-height: 100px !important;
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .comment-box {
    border: 1px solid $border-grey;
    padding: 1.2rem;  
  }

  .table {
    border: 1px solid $border-grey;
    padding: 0.5rem;
  }

  .totals {
    padding-top: 0.5rem;

    table {
      border: 1px solid $border-grey;
      border-collapse: collapse;
      margin-left: auto;

      td {
        border-collapse: collapse;
        border: 1px solid $border-grey;
        font-weight: bold;
        min-width: 10rem;
      }

      tr:nth-child(odd) td {
        background-color: $default-background-grey;
      }
    }
  }

  .address-summary-table {
    border: 1px solid $border-grey;

    .rt-thead {
      background-color: $white;
    }
    .rt-td,
    .rt-th {
      border-bottom: 1px solid $react-border;
      border-right: 1px solid $react-border;
    }
  }

  .comment-area {
    margin: 0 auto 0 auto;
    textarea {
      width: 100%;
      resize: none;
      display: block;
      margin: 0 auto 1rem auto;
    }
  }

  .sales-upload-grey {
    color: #747474 !important;
    font-size: 1.1rem;
    width: 10rem;
  }

  .sales-upload-blue {
    font-size: 1.1rem;
  }

  .model-list-table {
    border: 1px solid $react-border;
    padding: 0.5rem;

    &.bceid-user {
      border: none;
      padding: 0;

      .gap-left {
        margin-left: 0 !important;
      }

      .analyst-recommendation, .eligible-sales, .eligible-zev-credits, .rt-tfoot {
        display: none;
      }
    }

    .ReactTable {
      border: none;

      .rt-thead, .rt-tbody {
        min-width: 1500px !important;
      }  
    
      .rt-thead {
        background-color: $white;
        border-bottom: none;

        li {
          white-space: normal;
        }

        .sales-upload-blue, .sales-upload-grey {
          font-size: 1rem;
        }
      }

      .-headerGroups {
        border-bottom: none;

        .header-group {
          background-color: $default-background-grey;
          border: none !important;
          margin-bottom: 1rem;

          &.analyst-recommendation {
            background-color: $background-light-blue;
          }
        }
      }

      .-header {
        box-shadow: none;

        .rt-th {
          border-bottom: 1px solid $react-border;
          border-top: 1px solid $react-border;

          &.eligible-sales {
            background-color: $background-light-blue;
          }
        }
      }

      .gap-left {
        border-left: 1px solid $react-border;
        margin-left: 1rem;
      }

      .rt-th, .rt-td {
        border-bottom: 1px solid $react-border;
        border-right: 1px solid $react-border;
      }

      .rt-tfoot {
        box-shadow: none;
        margin-top: 0.5rem;

        .credits-applied-for, .sales-submitted {
          background-color: $default-background-grey;
          border-top: 1px solid $react-border;
        }

        .eligible-sales, .eligible-zev-credits {
          background-color: $background-light-blue;
          border-top: 1px solid $react-border;
        }

        .no-footer {
          display: none;
        }
      }

      .rt-tr {
        &.-odd {
          background-color: transparent;

          .rt-td {
            background-color: $default-background-grey;
          }  
        }

        &.danger .rt-td {
          background-color: $background-danger !important;
        }

        .rt-td:first-child, .rt-th:first-child {
          border-left: 1px solid $react-border;
        }
      }
    }
  }
}

#sales-details {
  .ql-container {
    height: auto !important;
  }

  .ql-editor {
    min-height: 100px !important;
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .comment-box {
    border: 1px solid $border-grey;
    padding: 1.2rem;  
  }

  .ReactTable {
    .rt-thead {
      background-color: $white;
    }

    .rt-thead, .rt-tbody {
      min-width: 1775px !important;
    }

    .-header {
      box-shadow: none;

      .rt-th {
        border-bottom: 1px solid $react-border;
        border-top: 1px solid $react-border;
      }
    }

    .-headerGroups {
      border-bottom: none;

      .header-group {
        background-color: $white;
        border-color: $white;
      }

      .header-margin {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }

    .-filters,
    .rt-tr-group {
      border-bottom: none;

      .-odd {
        background-color: transparent;

        .rt-td {
          background-color: $default-background-grey;
        }
      }
    }

    .-header,
    .-filters,
    .rt-tr-group {
      /* inner "padding" */
      .rt-td,
      .rt-th {
        border-bottom: 1px solid $react-border;

        &:first-child {
          border-left: 1px solid $react-border;
          margin-left: 0.5rem;
        }

        &:last-child {
          border-right: 1px solid $react-border;
          margin-right: 0.5rem;
        }

        &:not(:last-child) {
          border-right: 1px solid $react-border;
        }
      }
    }

    .rt-table {
      padding-bottom: 0.5rem;
    }

    .icbc-model-year {
      border-left: 1px solid $react-border;
    }

    .vin {
      border-left: 1px solid $react-border;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .warning {
      border-left: 1px solid $react-border;
      margin-left: 1rem;
    }
  }

  label {
    margin-top: 2vh;
    font-weight: bold;
    color: $default-text-blue;
    text-align: center;
  }

  #date-label {
    padding-left: 15px;
    width: 100%;
    text-align: left;
    margin-bottom: 0;
  }

  div.check {
    color: green;
    text-align: center;
  }

  input[type='date'] {
    position: relative;
    padding: 10px;
    margin: 5px 0 0 10px;
    border: 2px solid #616161;
    border-radius: 5px;
  }

  .calendar-icon {
    font-size: large;
    margin-left: 10px;
    margin-bottom: auto;
  }
}

#credit-transaction {
  .credit-balance-table,
  .credit-transaction-list-table {
    .-header {
      box-shadow: none;
      font-weight: bold;

      .rt-th {
        background-color: $default-background-grey;
        border-top: none;
        box-shadow: inset 0 0 0 0 rgb(0 0 0);
      }
    }

    .-headerGroups {
      border-bottom: none;
      font-weight: bold;

      .header-group {
        background-color: $default-background-grey;

        &.rt-th {
          border-bottom: none;
        }
      }

      .rt-tr {
        background-color: $white;
      }
    }

    .-filters,
    .rt-tr-group {
      border-bottom: none;

      .-even {
        background-color: transparent;

        .rt-td {
          background-color: $default-background-grey;
        }
      }

      .-odd {
        background-color: transparent;
      }
    }

    .-headerGroups,
    .-header,
    .-filters,
    .rt-tr-group {
      /* inner "padding" */
      .rt-td,
      .rt-th {
        &:first-child {
          margin-left: 0.5rem;
        }

        &:last-child {
          margin-right: 0.5rem;
        }
      }
    }

    .rt-table {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;

      .rt-th,
      .rt-td {
        border-right: none;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .balance-left,
    .credits-left,
    .date,
    .transaction {
      margin-left: 0.5rem;
    }

    .clickable:hover .rt-td {
      background-color: $primary-yellow;
    }
  }
}

.totals {
  table {
    td {
      padding: 3px 15px 3px 15px;
      border: 2px solid $border-grey;
    }

    .total-grey {
      background-color: $default-background-grey;
    }
  }
}
