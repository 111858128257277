button.listSelection {
  color: $primary-blue;
  background: none !important;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  padding: 0!important;

  &.active {
    background: $primary-yellow;
  }

}
