#supplementary {
  .supplementary-form {
    border: 1px solid $border-grey;
    padding: 1rem;

    .ql-container {
      height: auto !important;
    }
  }

  .sales-table-container {
    border: 1px solid $border-grey;

    .sales-table {
      border: None;
      .supplementary-input-disabled {
        background-color: #e9ecef;
        opacity: 1;
        border: 1px solid #ced4da;
        &.highlight {
          background-color: $background-warning;
        }
      }
    }
  }

  .bordered {
    border: 1px solid $border-grey;
    padding: 0.5rem;
  }

  .content {
    background-color: $default-background-grey;
  }

  .comment-box {
    border: 1px solid $border-grey;
    width: 100%;
    padding: 0.5rem 0.5rem;
  }

  .no-border {
    border: none;
  }

  .credit-selection {
    input[type='radio'] {
      margin-left: 3px;
    }

    td {
      background-color: $background-warning;
    }
  }

  .ldv-sales {
    height: 50px;
    line-height: 30px;
    font-weight: bold;
    background-color: $default-background-grey;
  }
  .compliance-reduction-table {
    border: 1px solid $border-grey;
  }
  table {
    width: 100%;
    border: 1px solid $border-grey;

    td {
      padding: 0 1rem 0 1rem;
      line-height: 35px;
    }
    tr {
      margin-top: 1rem;
    }
    .subclass {
      font-weight: bold;
      background-color: $default-background-grey;
    }
    .small-column {
      width: 10%;
    }
    .large-column {
      color: $default-text-blue;
      // width: 70%;
      padding-left: 1rem;
      border-right: 4px solid white;
    }
  }
  #offset-table {
    input {
      max-width: 125px;
      margin-left: 1rem;
    }
    td {
      line-height: 35px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .supplementary-report-tabs {
    .nav-item {
      padding: 1rem 0;

      &.DRAFT,
      &.UNSAVED,
      &.SAVED {
        a {
          border-bottom-color: $background-warning;
        }

        &.active span {
          border-bottom-color: $primary-yellow;
        }
      }

      &.CONFIRMED,
      &.SUBMITTED,
      &.RECOMMENDED,
      &.RETURNED {
        a {
          border-bottom-color: $background-light-blue;
        }

        &.active span {
          border-bottom-color: $default-text-blue;
        }
      }

      &.ASSESSED {
        a {
          border-bottom-color: $background-light-green;
        }

        &.active span {
          border-bottom-color: $alert-success;
        }
      }

      a,
      span {
        border-bottom: 1rem transparent solid;
        color: $default-text-black;
        display: block;
      }

      .disabled {
        opacity: 0.3;
      }
    }
  }

  .highlight.form-control {
    background-color: $background-warning !important;
  }
}
