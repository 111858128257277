#vehicle-supplier-details {
  h4 {
    color: $default-text-blue;
  }

  .supplier-text {
    color: $default-text-blue;
    font-size: large;
  }

  .ldv-sales {
    border: 1px solid $border-grey;
    display: inline-block;
    min-width: 450px;

    > .row {
      margin-left: 0;
      margin-right: 0;
    }

    .header-bg {
      background-color: $default-background-grey;

      > * {
        padding: 0.5rem;
      }
    }

    .model-year,
    .sales,
    .delete {
      display: inline-block;
      vertical-align: middle;
    }
    .delete button {
      background-color: transparent;
      border: none;
      color: $red;
    }
  }
}

h1 {
  color: $default-text-blue;
  font-size: 2rem;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.see-more {
  &-div {
    display: flex;
    justify-content: flex-end;
  }
  &-button {
    color: #003366;
    border: none;
    background: $white;
    text-decoration: underline;
    margin-right: 10rem;
    margin-bottom: 1rem;
  }
}

#supplied-ldv-by-year {
  margin-bottom: 1rem;
}
