#dashboard {
  padding: 0 1rem;

  .dashboard-card, .dashboard-fieldset {
    margin: 2rem 0;
    padding: 1rem;

    &:first-child {
      margin-top: 1rem;
    }

    &:last-child {
      margin-bottom: 1rem;
    }
  }

  .dashboard-card {
    background-color: $default-background-grey;
    color: $default-text-blue;
    font-size: 1rem;

    text-align: center;

    a, a > span {
      color: $default-link-blue;
      text-decoration: underline;
    }

    h2 {
      font-size: 1.5rem;
      margin: 0;
      padding: 0;
    }

    .content {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .icon {
      svg {
        color: $primary-yellow;
        font-size: 3rem;
      }
    }

    .pdf-link {
      svg {
        color: $primary-yellow;
        font-size: 2rem;
        margin-left: 0.5rem;
      }
    }

    .label {
      font-size: 1.2rem;
    }

    .value {
      color: $primary-yellow;
      font-size: 2rem;
    }
  }

  .dashboard-fieldset {
    border: 1.5px solid #000;
    font-size: 1.1rem;
    position: relative;
    padding:1rem;
  
    a {
      color: $default-link-blue;
      text-decoration: underline;
    }
  
    button {
      background-color: transparent;
      border: none;
      color: $default-link-blue;
      margin: .3rem 0 .5rem 0;
      outline: none;
      padding: 0;
      text-align: left;
      text-decoration: underline;
      font-size: 1.1rem;
  
      &:hover, &:focus {
        text-decoration: underline;
      }
    }

    h1 {
      color: $primary-blue;
      font-size: 1.75rem;
      padding: 0 0.7rem;
    }

    #action-org-name {
      padding-left: 1.5rem;
    }

    h5 {
      font-size: 1.2rem;
      color: $primary-blue;
      margin: 0;
      padding: 1rem 0 0 0;
    }

    dd {
      font-size: 1.1rem;
      margin-bottom: 0;
      color: $primary-blue;
    }

    .content {
      position: relative;

      &:first-child {
        margin-top: 1rem;
      }

      &:last-child {
        margin-bottom: 1rem;
      }
    }

    .divider {
      margin: 0 1rem;
    }

    .icon-upload {
      display: inline-block;
      font-size: 2.5rem;
      vertical-align: middle;
    }

    .text {
      margin-left: 5rem;
      > a, > button {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .value {
      color: $primary-yellow;
      font-size: 3rem;
      text-decoration: none;
      vertical-align: middle;
      padding-right: 1rem;
    }
  }

  .pdf-link {
    * {
      display: inline-block;
      vertical-align: middle;
    }

    svg {
      color: $primary-yellow;
      font-size: 2rem;
      margin-left: 0.5rem;
    }
  }

  @media (min-width: 1200px) and (max-width: 1480px) {
    .col-xl-3 {
      flex: 0 0 30% !important;
      max-width: 30% !important;
    } 
  }
}

#actions {
  h1 {
    text-align: left;
  }
  .activity-banner {
    width: 100%;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    #action-icon {
      width: 3rem;
    }
    .activity-text {
      display: flex;
      margin-left:2rem;
      text-align: left;
    }
    #fa-arrow {
      transform: scale(.8,1.6);
      margin-left: auto;
    }
    .blue {
      color: $default-text-blue;
    }
    .yellow {
      color: $primary-yellow;
    }
    .green {
      color: green;
    }
    .red {
      color: #a12622;
    }
    div {
      margin: auto 20px auto 10px;
    }
  }
  h3 {
    font-size: 1.35rem;
    margin: 0;
    padding: 0;
    font-weight: bold;
  }
  .icon-secure {
    display: inline-block;
    font-size: 1rem;
    vertical-align: middle;
  }
  @media(max-width:576px){
    .activity-banner, .activity-banner * {
      display: block;
      text-align: center;
      svg {
        margin: 0 auto;
      }
      #fa-arrow {
        display: none;
      }
    }
    .activity-banner .activity-text{
      display: block;
      padding: 0;
      margin: 0;
    }
  }
}

#organization-details, #user-settings, #administration {
  .content {
    .text {
      margin-left: 0rem;
      padding-left: 1.5rem;
    }
    .value {
      width: 2rem;
    }
  }
  .icon {
    color: $primary-yellow;
    font-size: 3rem;
    position: absolute;
    right: 2rem;
    top: 0;
  }
  .play-video {
    color: $primary-yellow;
    font-size:13px;
  }
}

#administration.dashboard-fieldset, #user-settings.dashboard-fieldset {
  h1 {
    margin-bottom: 1rem;
    padding: 0;
  }

  h5 {
    margin: 0;
    padding: 0;
  }

  .content {
    margin-bottom: 1rem;
    padding: 0;

    button {
      display: block;
      margin: 0;
      padding: 0;
    }

    svg {
      color: $primary-yellow;
      float: left;
      margin: 5px 0.5rem 0 0;
    }

    &.list {
      svg {
        display: inline-block;
        float: none;
        vertical-align: middle;
      }
    }

    .text {
      padding: 0;

      dl:last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media(max-width:576px) {
   svg {
     display: none;
   }
  }
}
