#form {
  .request-changes-vehicle {
    font-weight:bold;
    color: $red;
  }
  fieldset {
    border: 1px solid $default-background-grey;
    padding: 0.5rem;

    legend {
      color: $default-text-blue;
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 0;
      margin-left: 1rem;
      padding: 0 1rem 0;
      width: auto;
    }

    .form-group {
      background-color: $default-background-grey;
      margin: 0.5rem 0;
      padding: 1rem;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.action-bar {
        margin-top: 2rem;
      }

      &.row {
        &.error {
          background-color: #fff2f2;
        }
        label {
          margin-top: 0;
        }
      } 
    }
  }

  input[type=radio], input[type=checkbox] {
      margin-right: .5rem;      
  }
  input[type=text], select {
    border: 2px solid $default-text-black;
  }

  label {
    color: $default-text-blue;
    font-weight: bold;
  }
  .delete-button {
      border: 1px solid red;
      border-radius: 5%;
      color: red;
      padding: 0 1rem 0 1rem;
  }
  ul {
      padding: 0;
      white-space: nowrap;
  }
}

.react-autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.transfer-input-width {
  width: 100px;
}
#transfer-text {
  font-weight: normal !important;
}
.transfer-row-x {
  position: relative;
  float: right;
  border: none;
  font-weight: bold;
  color: $default-text-blue;
  background-color: transparent;
}
.transfer-total {
  float: right;
  font-weight: bold;
  color: $default-text-blue;
  padding-right:20px;
}

.transfer-add-line {
  border: none;
  background-color: $white;
}
.has-currency {
  position: relative;
  > input {
    padding-left: 1.25rem;
  }
  .currency-symbol {
    z-index:100;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-left: .5rem;
  }
}